<template>
  <div class="container">
    <div class="banner">
      <swiper ref="mySwiper" class="banner" :options="swiperOption" :not-next-tick="true">
        <swiper-slide
          v-for="items in swiperSlides"
          :key="items.bannerId"
        >
          <img :src="items.bannerImg">
        </swiper-slide>
        <div slot="button-prev" class="swiper-button-prev" />
        <div slot="button-next" class="swiper-button-next" />
      </swiper>
    </div>
    <div class="paddingPub sixContent">
      <div class="sixBox">
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g1.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.lht.title') }}</p>
            <span>{{ $t('about.fourBox.lht.desc') }}</span>
          </div>
        </div>
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g2.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.srdk.title') }}</p>
            <span>{{ $t('about.fourBox.srdk.desc') }}</span>
          </div>
        </div>
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g3.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.jyzh.title') }}</p>
            <span>{{ $t('about.fourBox.jyzh.desc') }}</span>
          </div>
        </div>
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g4.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.yalyjj.title') }}</p>
            <span>{{ $t('about.fourBox.yalyjj.desc') }}</span>
          </div>
        </div>
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g5.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.yzdk.title') }}</p>
            <span>{{ $t('about.fourBox.yzdk.desc') }}</span>
          </div>
        </div>
        <div @click="$router.push('/apply')" class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g6.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.scwj.title') }}</p>
            <span>{{ $t('about.fourBox.scwj.desc') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="paddingPub shareBox">
      <div class="shareTitle">
        <div class="h1">{{ $t('gafx.title') }}</div>
        <div class="h3">{{ $t('gafx.desc') }}</div>
      </div>
      <div class="shareContent">
        <swiper class="shareBanner" :options="swiperShare" :not-next-tick="true">
          <swiper-slide
            v-for="(items, index) in swiperShareSlides"
            :key="items.caseId"
          >
            <div class="answerBox">
              <div class="fonthtml">
                <div v-html="$lan === 'tc' ? items.caseContent : items.simplifiedContent" class="vHtml" />
                <div class="text-right">
                  <a-button class="btn-radius" type="primary" shape="round" @click="$router.push('/apply')">{{ $t('sqjk') }}</a-button>
                </div>
              </div>
              <div class="avatar">
                <a-avatar :src="items.caseUserImage" :size="48" />
                <div class="ansDesc">
                  <div class="ansName">{{ $lan === 'tc' ? items.caseUserName : items.simplifiedUserName }}</div>
                  <div class="ansJob">
                    {{ $t('zsga') }}({{ index + 1 }}) |
                    <span>{{ $lan === 'tc' ? items.caseIdentity : items.simplifiedIdentity }}</span>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div slot="button-prev" class="swiper-button-prev shareButton-prev" />
          <div slot="button-next" class="swiper-button-next shareshareButton-next" />
          <div slot="pagination" class="swiper-pagination sharePagination" />
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getCase } from '../utils/request'
export default {
  name: 'Index',
  data() {
    const width1280 = window.innerWidth <= 1280
    const width768 = window.innerWidth <= 768
    return {
      swiperOption: {
        autoplay: 8000,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperShare: {
        autoplay: 8000,
        slidesPerView: width1280 ? width768 ? 1 : 1 : 2,
        spaceBetween: 36,
        slidesPerColumn: width1280 ? 2 : 1,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperSlides: [],
      swiperShareSlides: []
    }
  },
  mounted() {
    // 轮播图
    this.getBannerList()
    // 个人案例
    this.getCaseList()
  },
  methods: {
    async getBannerList() {
      const result = await getBanner()
      if (result.head.success) {
        this.swiperSlides = result.body
      } else {
        this.$message.error(result.head.message)
      }
    },
    async getCaseList() {
      const result = await getCase()
      if (result.head.success) {
        this.swiperShareSlides = result.body
      } else {
        this.$message.error(result.head.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  height: 100vh;
  img{
    margin-top: 0;
  }
}
.sixContent{
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #F5F9FD;
  .sixBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    .sixItems{
      width: 30%;
      height: 272px;
      cursor: pointer;
      text-align: center;
      position: relative;
      border-top: 4px solid #000B8B;
      border-left: 1px solid #000B8C;
      border-right: 1px solid #000B8C;
      border-bottom: 1px solid #000B8C;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 24px 24px;
      margin-bottom: 3rem;
      >div{
        position: relative;
        z-index: 1;
      }
      .imgBox{
        width: 157px;
        height: 100px;
        align-self: flex-end;
        img{
          object-fit: contain;
        }
      }
      .desc{
        width: 100%;
        text-align: center;
        align-self: flex-end;
        font-weight: bold;
        line-height: 36px;
        p{
          font-size: 22px;
        }
        span{
          color: #777777;
        }
      }
      &:first-of-type{
        border-top: 3px solid #10CFC9;
        background-color: #000B8B;
        color: #ffffff;
      }
      &:after{
        width: 100%;
        height: 100%;
        content: '';
        border: 1px solid #000B8B;
        border-radius: 10px;
        position: absolute;
        top: 9px;
        right: -9px;
        z-index: -1;
      }
    }
  }
}
.shareBox{
  text-align: center;
  background: #ffffff;
  padding-top: 6rem;
  padding-bottom: 6rem;
  .shareTitle{
    line-height: 40px;
    margin-bottom: 3rem;
    .h1{
      font-size: 32px;
      color: #000000;
      font-weight: 600;
    }
    .h3{
      font-size: 20px;
      color: #727272;
      font-weight: 400;
    }
  }
  .shareContent{
    .answerBox{
      margin: 0 3.4rem;
      .fonthtml{
        position: relative;
        border: 1px solid #000B8C;
        background: #fff;
        border-radius: 10px;
        padding: 26px 24px 18px;
        text-align: left;
        .vHtml {
          margin: 0 0 10px;
          height: 96px;
          line-height: 24px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #494949;
        }
        &::after, &::before{
          position: absolute;
          content: '';
          border: 14px solid;
        }
        &::after{
          border-color: #fff transparent transparent transparent;
          left: 27px;
          bottom: -27px;
        }
        &::before{
          left: 27px;
          bottom: -28px;
          border-color: #000B8C transparent transparent transparent;
        }
      }
      .avatar{
        display: flex;
        align-items: center;
        text-align: left;
        margin-top: 24px;
        padding-left: 24px;
        .ansDesc{
          margin-left: 10px;
          color: #303030;
          .ansName{
            font-weight: bold;
            font-size: 16px;
          }
          .ansJob{
            font-size: 12px;
          }
        }
      }
    }
    .sharePagination{
      display: none;
    }
  }
}
.btn-radius{
  font-weight: bold;
}
@media screen and (max-width: 1680px) {
  .banner{
    height: 80vh;
  }
  .sixContent{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
@media screen and (max-width: 1280px) {
  .sixContent{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media screen and (max-width: 992px) {
  .banner{
    height: 62vh;
  }
  .sixContent{
    padding-top: 4rem;
    padding-bottom: 4rem;
    .sixBox{
      .sixItems{
        width: 45%;
        height: 242px;
        margin-bottom: 1rem;
        .imgBox{
          width: 127px;
          height: 70px;
        }
      }
    }
  }
  .shareBox{
    padding-top: 4rem;
    padding-bottom: 4rem;
    .shareTitle{
      margin-bottom: 1rem;
      .h1{
        font-size: 26px;
      }
      .h3{
        font-size: 14px;
      }
    }
    .shareContent{
      .shareBanner{
        padding-bottom: 3rem;
      }
      .answerBox{
        margin: 0;
      }
      .sharePagination{
        display: block;
        top: 95%;
        left: 50%;
        right: -50%;
        transform: translateX(-50%);
      }
      .shareButton-prev, .shareshareButton-next{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .banner{
    height: 40vh;
  }
  .sixContent{
    padding-top: 2rem;
    padding-bottom: 2rem;
    .sixBox{
      .sixItems{
        width: 47%;
        height: 160px;
      }
    }
  }
  .shareBox{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .banner{
    height: 28vh;
  }
  .sixContent{
    .sixBox{
      .sixItems{
        width: 45%;
        height: 242px;
        margin-bottom: 1rem;
        .imgBox{
          height: 40px;
        }
        .desc{
          p{
            font-size: 14px;
          }
          span{
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
